import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';

import {
    container,

    menuContainer,
    menuContainer_open,
    menu,
    menuItem,

    menuButton,
    menuButton_close
} from './style.module.css'

/**
 * Header nav menu
 * 
 * @param {Array} data  nav data
 */
const Menu = ({
    data = []
}) => {
    const [mob_menu_view, toggle_mob_menu_view] = React.useState(false);

    React.useEffect(() => {
        const body = document.querySelector('body');

        // scroll disabled when menu is open
        if (mob_menu_view) {
            body.style.overflow = 'hidden';
            body.style.position = 'fixed';
            body.style.top = `-${window.pageYOffset}px`;
            body.style.width = '100%';

        // return to default values
        } else {
            body.style.overflow = '';
            body.style.position = '';
            body.style.top = ``;
            body.style.width = '';
        }
    }, [mob_menu_view])

    // elements classes
    let menuButtonClass = `${menuButton} hidden md:flex`,
        menuContainerClass = `${menuContainer}`;

    if (mob_menu_view) {
        menuButtonClass += ` ${menuButton_close}`;
        menuContainerClass += ` ${menuContainer_open}`
    }

    return(
        <div className={`${container} flex items-center w-full`}>
            <button type='button'
                className={menuButtonClass}
                onClick={() => toggle_mob_menu_view(!mob_menu_view)}>

            </button>
            <nav className={`${menuContainerClass} flex w-full md:flex-col`}>
                {
                    data ?
                    <ul className={`${menu} flex md:flex-col`}>
                        {
                            data.map((menu_item, index) => (
                                <li key={index} className={`${menuItem} flex md:border-b md:border-b-light-grey`}>
                                    <Link to={menu_item.link} className='text-sm md:py-[16px] w-full'>
                                        {menu_item.name}
                                    </Link>
                                </li>
                            ))
                        }
                    </ul> : null
                }
                <div className='flex flex-col justify-between z-10 items-start hidden md:flex mt-[16px]'>
                    <a href='mailto:auto@onescan.pro' className='text-sm whitespace-nowrap'>auto@onescan.pro</a>
                    <a href="tel:+420601256408" className='text-sm whitespace-nowrap mt-[6px]'>+420 601 256 408</a>
                </div>
            </nav>
        </div>
    )
}

Menu.propTypes = {
    data: PropTypes.array
}

export default Menu;