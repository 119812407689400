import React from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'

import { FOOTER_TEXT } from '../../const/lang/footer'

import {
    footer,
    footerLink,
    logo,
} from './style.module.css'

/**
 * Site footer
 * 
 * @param {Array} data  nav data
 * @param {Array} lang  current language
 */
const Footer = ({
    data = [],
    lang = 'ru'
}) => (
    <footer className={`${footer} w-full default-container mt-auto mx-auto border-t border-t-light-grey flex flex-col`}>
        <section>
            <nav>
                <ul className='flex justify-center'>
                    {
                        data.map((item, index) => (
                            <li key={index} className={`${footerLink} text-sm`}>
                                <Link to={item.link}>{item.name}</Link>
                            </li>
                        ))
                    }
                </ul>
            </nav>
        </section>
        <section className='flex justify-center mt-[24px]'>
            <a href='mailto:auto@onescan.pro' className={`${footerLink} text-sm whitespace-nowrap`}>auto@onescan.pro</a>
            <a href="tel:+420601256408" className={`${footerLink} text-sm whitespace-nowrap`}>+420 601 256 408</a>
        </section>
        <Link to={'/'} className={`${logo} mx-auto mt-[24px]`}>
            <img src={'assets/logo/onescan-logo.svg'} alt='logo' />
        </Link>
        <div className='flex justify-center mt-[16px]'>
            <p className='text-sm text-center'>{FOOTER_TEXT[lang]}</p>
        </div>
    </footer>
)

Footer.propTypes = {
    data: PropTypes.array,
}

export default Footer