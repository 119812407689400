import React from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'

import Menu from './Menu'

import {
    header,
    logo
} from './style.module.css'

/**
 * Site header
 * 
 * @param {Array} menu_data     nav data
 */
const Header = ({
    menu_data = []
}) => {

    return(
        <header className={`${header} flex border-b border-b-light-grey`}>
            <Link className={logo} to={'/'}>
                <img src={'assets/logo/onescan-logo.svg'} alt='logo' />
            </Link>
            <Menu data={menu_data} />
            <div className='flex flex-col justify-between z-10 items-start md:hidden'>
                <a href='mailto:auto@onescan.pro' className='text-sm whitespace-nowrap'>auto@onescan.pro</a>
                <a href="tel:+420601256408" className='text-sm whitespace-nowrap'>+420 601 256 408</a>
            </div>
        </header>
    )
}

Header.propTypes = {
    menu_data: PropTypes.array,
}

export default Header