import React from "react";
import { graphql } from "gatsby";

import LayoutContainer from "../containers/LayoutContainer";
import Seo from "../components/seo";
import StaticPageContent from "../components/Main/StaticPage";

import menu from '../data/StaticPage/ru.menu.json';
import footer_data from '../data/ru.footer.json';

/**
 * Static page template
 * 
 * @param {Object} data   graphQL data
 */
const StaticPage = ({
  data = null,
  pageContext = {},
}) => {
  const lang = pageContext.lang;

  const markdown_frontmatter = data.markdownRemark.frontmatter;

  return (
    <LayoutContainer
      menu_data={menu.menu}
      footer_data={footer_data.menu}
      lang={lang}>
      <Seo
        title={markdown_frontmatter.title}
        description={markdown_frontmatter.description} />
      <StaticPageContent markdown={data.markdownRemark} />
    </LayoutContainer>
  )
}

/**
 * GraphQL request for `netlify` static markdown
 */
export const pageQuery = graphql`
  query StaticPage($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
        description
      }
    }
  }
`;

export default StaticPage