import React from 'react';
import PropTypes from 'prop-types';

/**
 * Static page
 * 
 * @param {Object} markdown  `netlify` markdown data
 */
const StaticPageContent = ({
    markdown = null,
}) => {

    return(
        <div className='static-page flex flex-col'>
            <div className='header-container flex flex-col'>
                <h1 className='text-center sm:text-left'>{markdown.frontmatter.title}</h1>
                {
                    markdown.frontmatter.description ?
                    <p>{markdown.frontmatter.description}</p> : null
                }
            </div>
            <div dangerouslySetInnerHTML={{ __html: markdown.html }} />
        </div>
    )
}

StaticPageContent.propTypes = {
    markdown: PropTypes.object,
}

export default StaticPageContent;