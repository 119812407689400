import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

const default_values = {
    title: 'Onescan',
    description: 'Onescan',
    lang: 'ru',
    author: 'BRDN',
    canonical: '',
    type: 'website',
    keywords: 'onescan, подбор автомобилей, чехия',
}
/**
 * Page SEO
 * 
 * @param {String} description  page meta description
 * @param {String} lang         page meta language
 * @param {String} title        page meta title
 * @param {String} canonical    canonical
 * @param {String} keywords     search keywords
 * @param {String} type         page type
 */
const Seo = ({
    title = default_values.title,
    description = default_values.description,
    canonical = default_values.canonical,
    keywords = default_values.keywords,
    type = default_values.type,
    lang = default_values.lang,
}) => {

    return (
        <Helmet
            htmlAttributes={{
                lang,
            }}
            title={title}
            titleTemplate={`%s | ${default_values.title}`}
            meta={[
                {
                    name: `description`,
                    content: description,
                },
                {
                    name: `Keywords`,
                    content: keywords,
                },
                {
                    name: `canonical`,
                    content: canonical,
                },
                {
                    property: `og:title`,
                    content: title,
                },
                {
                    property: `og:description`,
                    content: description,
                },
                {
                    property: `og:type`,
                    content: type,
                },
                {
                    property: `og:locale`,
                    content: `cs_CZ`,
                },
                {
                    property: `og:url`,
                    content: canonical,
                },
                {
                    name: `twitter:card`,
                    content: `summary`,
                },
                {
                    name: `twitter:creator`,
                    content: default_values.author,
                },
                {
                    name: `twitter:title`,
                    content: title,
                },
                // {
                //     name: `twitter:site`,
                //     content: '@...',
                // },
                {
                    name: `twitter:description`,
                    content: description,
                },
            ]}
        >
            <link rel="apple-touch-icon" sizes="180x180" href="assets/favicon/apple-touch-icon.png" />
            <link rel="icon" type="image/png" sizes="32x32" href="assets/favicon/favicon-32x32.png" />
            <link rel="icon" type="image/png" sizes="16x16" href="assets/favicon/favicon-16x16.png" />
            <link rel="mask-icon" href="assets/favicon/safari-pinned-tab.svg" color="#5bbad5" />
            <meta name="msapplication-TileColor" content="#da532c" />
            <meta name="theme-color" content="#ffffff" />
        </Helmet>
    );
}

Seo.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
    lang: PropTypes.string,
    canonical: PropTypes.string,
    keywords: PropTypes.string,
    type: PropTypes.string,
};

export default Seo;