import React from 'react';
import PropTypes from 'prop-types';

import Seo from '../components/seo';
import Footer from '../components/Footer';
import Header from '../components/Header';

/**
 * App high-level page wrapper
 * 
 * @param {Object} children     children blocks
 * @param {Array} menu_data     header menu data
 * @param {Array} footer_data   footer menu data
 */
const LayoutContainer = ({
    children = null,
    menu_data = [],
    footer_data = [],
}) => {

    return(
        <div className='flex flex-col w-full h-full default-container mx-auto'>
            <Seo title="..." />
            <Header menu_data={menu_data} />
            {children}
            <Footer data={footer_data} />
        </div>
    )
}

LayoutContainer.propTypes = {
    children: PropTypes.object.isRequired,
    menu_data: PropTypes.array,
    footer_data: PropTypes.array,
}

export default LayoutContainer;